:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

:global body.lw-global-theme {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-stroke: 0.45px rgba(0, 0, 0, 0.1);
  font-family: "Open Sans", "Segoe UI", Tahoma, sans-serif;
  --land-ui-font-family: Open Sans, Segoe UI, Tahoma, sans-serif;
  --land-ui-font-color: off-black;
  --land-ui-selection-color: #fa6401;
  --land-ui-body-font-weight: 600;
  --land-ui-caption-1-font-weight: 600;
}
:global body.lw-global-theme input,
:global body.lw-global-theme textarea {
  font-family: "Open Sans", "Segoe UI", Tahoma, sans-serif;
}
:global body.lw-global-theme .land-ui-dropdown {
  --land-ui-dropdown-border-radius: 3px;
  --land-ui-dropdown-selected-option-icon-url: url("/assets/images/lw-bundle.svg#lw-orange-check-mark-32px");
}
:global body.lw-global-theme .land-ui-radio-button-group {
  --land-ui-radio-button-group-dot-color: #fa6401;
}

:global body.loa-global-theme {
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-stroke: 0.45px rgba(0, 0, 0, 0.1);
  font-family: Roboto, "Franklin Gothic Medium", Tahoma, sans-serif;
}

:global body.laf-global-theme {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-stroke: 0.45px rgba(0, 0, 0, 0.1);
  font-family: Poppins, sans-serif;
  --land-ui-font-family: Poppins, sans-serif;
  --land-ui-font-color: #333333;
  --land-ui-selection-color: #d3ae48;
  --land-ui-caption-1-line-height: 21px;
}
:global body.laf-global-theme .phone {
  margin-bottom: 10px;
}
:global body.laf-global-theme input,
:global body.laf-global-theme textarea {
  font-family: Poppins, sans-serif;
  border-radius: 0;
}
:global body.laf-global-theme a {
  font-weight: normal;
  cursor: pointer;
  color: #000;
  font-weight: normal;
}
@media (hover: hover) and (pointer: fine) {
  :global body.laf-global-theme a:hover {
    color: #b9983f;
    border-bottom: none;
  }
}
:global body.laf-global-theme a:hover {
  color: #d3ae48;
  border-bottom: none;
}
:global body.laf-global-theme .ccmp-mpt-header {
  background-color: #173f3f;
  color: #fff;
}
:global body.laf-global-theme .ccmp-mpt-header a {
  cursor: pointer;
  color: #fff;
  font-weight: normal;
}
@media (hover: hover) and (pointer: fine) {
  :global body.laf-global-theme .ccmp-mpt-header a:hover {
    color: #b9983f;
    border-bottom: none;
  }
}
:global body.laf-global-theme #root {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(8.11%, #ffffff), color-stop(188.19%, rgba(255, 255, 255, 0)));
  background: linear-gradient(180deg, #ffffff 8.11%, rgba(255, 255, 255, 0) 188.19%);
}
:global body.laf-global-theme #root h5 {
  font: 700 22px/29px Poppins, sans-serif;
}
:global body.laf-global-theme #root > footer {
  background-color: #173f3f;
  color: #fff;
  text-transform: none;
}
:global body.laf-global-theme #root > footer > div {
  background-color: #173f3f;
  color: #d3ae48;
}
:global body.laf-global-theme #root > footer > div > a {
  background-color: transparent;
}
:global body.laf-global-theme .input {
  border-radius: 0;
  height: 40px;
}
:global body.laf-global-theme .land-ui-dropdown {
  --land-ui-dropdown-border-radius: 3px;
  --land-ui-dropdown-selected-option-icon-url: url("/assets/images/laf-gold-check-mark-32px.svg");
}
:global body.laf-global-theme .land-ui-radio-button-group {
  --land-ui-radio-button-group-dot-color: #d3ae48;
}