:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.noscript {
  border: solid black 1px;
  width: 100%;
  height: 70px;
  text-align: center;
  color: red;
}

.main {
  min-height: 100vh;
}

:global(.Broker-Directory-New) .main {
  min-height: calc(100vh - 336px);
}