:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.mobile-nav-placeholder {
  padding-bottom: 55px;
}

.mobile-contact-footer-placeholder {
  padding-bottom: 48px;
}

.footer-sticky-nav-placeholder {
  padding-bottom: 72px;
}

.footer {
  background: #253732;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  padding: 30px 15px;
}

.content {
  width: 100%;
  max-width: 1601px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.heading {
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
}

.ul {
  padding: 0;
  list-style: none;
}

.li {
  height: 24px;
}

.stacked {
  width: 50%;
}

.links {
  width: 100%;
}

.link {
  color: #fff;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
  -webkit-text-decoration-color: #fff;
          text-decoration-color: #fff;
}

.info {
  width: 100%;
}

.about {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 100%;
  max-width: 1601px;
}

.rights {
  font-weight: initial;
  text-align: center;
}

.social {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  width: 164px;
}

.social-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  color: white;
  cursor: pointer;
}

@media only screen and (min-width: 601px) {
  .links {
    width: 50%;
  }
}
@media only screen and (min-width: 901px) {
  .about {
    -ms-flex-direction: row;
        flex-direction: row;
  }
  .links {
    width: 25%;
  }
  .rights {
    text-align: left;
  }
  .mobile-contact-footer-placeholder,
  .footer-sticky-nav-placeholder {
    padding-bottom: 0px;
  }
}
@media only screen and (min-width: 990px) {
  .mobile-nav-placeholder {
    padding-bottom: 0px;
  }
}