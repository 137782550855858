:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.trigger {
  color: #333333;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  cursor: pointer;
  padding-top: 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: start;
      justify-content: flex-start;
  -ms-flex-align: center;
      align-items: center;
}
.trigger > span {
  display: inline-block;
  background: url("/assets/images/shared-bundle.svg#arrow-down") no-repeat center center;
  width: 20px;
  height: 20px;
  background-size: contain;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  fill: #333333;
}

.arrow-up {
  -webkit-transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

:global(.laf-global-theme) .arrow {
  display: none;
}
:global(.laf-global-theme) .footer-trigger {
  color: white;
  font-size: 18px;
}
:global(.laf-global-theme) .footer-trigger > span {
  display: inline-block;
  background: url("/assets/images/laf-bundle.svg#arrow-down-white") no-repeat center center;
  width: 24px;
  height: 24px;
  background-size: contain;
  fill: #fff;
}
:global(.laf-global-theme) .footer-trigger:hover {
  color: #b9983f;
}
:global(.laf-global-theme) .footer-trigger:hover > span {
  background: url("/assets/images/arrow-down-dark-gold.svg") no-repeat center center;
}
:global(.laf-global-theme) .footer-trigger .arrow {
  display: block;
}

:global(.lw-global-theme) .trigger {
  font-weight: 700;
}
:global(.lw-global-theme) .footer-trigger {
  display: inline-block;
  padding-top: 0px;
  color: #fa6401;
  font-size: 16px;
}

:global(.loa-global-theme) .footer-trigger {
  color: #fff;
  -ms-flex-align: end;
      align-items: flex-end;
}
:global(.loa-global-theme) .footer-trigger span {
  background-image: url("/assets/images/land-bundle.svg#arrow-down-white");
}