:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

:global(.loa-global-theme) {
  /* 
     Land.com Home Page and Land.com Diamond Level Listing Details Pages will display the transparent header.
     The parent header container needs a height of 0px to prevent CLS, because the transparent header does not push
     the page content down like the standard header does*/
}
:global(.loa-global-theme) .header {
  height: 56px;
}
:global(.loa-global-theme) .transparent-header {
  height: 0px;
}

:global(.laf-global-theme) .header {
  height: 65px;
}

:global(.lw-global-theme) .header {
  height: 56px;
}
@media only screen and (min-width: 901px) {
  :global(.lw-global-theme) .header {
    height: 60px;
  }
}