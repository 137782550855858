@charset "UTF-8";
:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  background: #fff;
  color: #333333;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  -ms-touch-action: auto;
      touch-action: auto;
  overflow-x: hidden;
  overflow-y: overlay;
}

a {
  text-decoration: none;
  color: inherit;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width: 12px;
}

::-webkit-scrollbar:horizontal {
  height: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  border: 2px solid rgba(0, 0, 0, 0.4);
  background-clip: padding-box;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
  opacity: 0;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-input-placeholder {
  color: #aaaaaa;
}

/* Firefox 4-18 */
:-moz-placeholder {
  color: #aaaaaa;
}

/* Firefox 19-50 */
::-moz-placeholder {
  color: #aaaaaa;
}

/* - Internet Explorer 10–11
 - Internet Explorer Mobile 10-11 */
:-ms-input-placeholder {
  color: #aaaaaa;
}