:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.footer {
  background: #333333;
  color: #fff;
  padding-bottom: 32px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}

.content {
  width: 100%;
  max-width: 1601px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: start;
      justify-content: flex-start;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 0px 0px 22px 0px;
}

.heading {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase;
  margin: 0 0 21px 0;
}

.ul {
  padding: 0;
  list-style: none;
}

.state-links {
  text-align: left;
  margin: 27px 0px 0px 27px;
}
.state-links ul {
  padding: 0;
  margin: 2px 0;
  list-style: none;
}

.popular-list-container {
  -ms-flex-preferred-size: 70%;
      flex-basis: 70%;
  margin: 27px 0px 0px 27px;
}
.popular-list-container ul {
  padding-right: 33%;
  -webkit-column-count: 2;
     -moz-column-count: 2;
          column-count: 2;
  list-style: none;
}

.resource-links,
.about-links {
  text-align: justify;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0px 0px 0px 27px;
}

.state-links li a,
.resource-links li a,
.about-links li a,
.ul li a {
  color: #fff;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}
.state-links li a:hover,
.resource-links li a:hover,
.about-links li a:hover,
.ul li a:hover {
  -webkit-text-decoration-color: #d3ae48;
          text-decoration-color: #d3ae48;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
}

.page-end {
  width: 100%;
  max-width: 1601px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  border-top: solid 2px #a9a9a9;
  padding: 1rem 0;
  text-align: center;
  -ms-flex-pack: start;
      justify-content: flex-start;
}
.page-end .social-follow {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-right: 0.5rem;
  text-align: justify;
}
.page-end .page-end-containers {
  margin: 0 0 35px 27px;
}
.page-end .social {
  text-align: justify;
}
.page-end .copy {
  font-size: 16px;
  color: white;
}
.page-end .facebook,
.page-end .twitter,
.page-end .instagram {
  cursor: pointer;
  margin: 0 0.5rem 0 0.5rem;
  position: relative;
  top: 5px;
}
.page-end .facebook:hover,
.page-end .twitter:hover,
.page-end .instagram:hover {
  -webkit-filter: brightness(0.5);
          filter: brightness(0.5);
}

.facebook {
  display: inline-block;
  background: url("/assets/images/lw/facebook.svg") no-repeat center center;
  width: 24px;
  height: 24px;
  background-size: contain;
}

.twitter {
  display: inline-block;
  background: url("/assets/images/lw/twitter.svg") no-repeat center center;
  width: 24px;
  height: 24px;
  background-size: contain;
}

.instagram {
  display: inline-block;
  background: url("/assets/images/lw/instagram.svg") no-repeat center center;
  width: 24px;
  height: 24px;
  background-size: contain;
}

.footer-logo {
  -ms-flex-positive: 4;
      flex-grow: 4;
  min-width: 33%;
  display: -ms-flexbox;
  display: flex;
}

.logo-link {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
}
.logo-link:focus {
  outline: none;
}

.logo {
  height: 100%;
}
.logo:focus {
  outline: none;
}

:global(.laf-global-theme) .logo {
  display: inline-block;
  background: url("/assets/images/laf-bundle.svg#laf-logo") no-repeat center center;
  width: 152px;
  height: 20px;
  background-size: contain;
  background-size: contain;
}

@media only screen and (min-width: 375px) {
  .popular-list-container {
    width: 100%;
  }
  .popular-list-container ul {
    -webkit-column-count: 1;
       -moz-column-count: 1;
            column-count: 1;
    padding: unset;
    min-width: 285px;
  }
  .copy,
  .social {
    width: 92%;
    -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
    white-space: pre-line;
  }
  .resource-links {
    margin: 0px 27px 0px 27px;
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
  }
  .page-end-containers {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
  }
}
@media only screen and (min-width: 601px) {
  .popular-list-container {
    -ms-flex-preferred-size: 94%;
        flex-basis: 94%;
  }
  .popular-list-container ul {
    -webkit-column-count: 2;
       -moz-column-count: 2;
            column-count: 2;
    padding-right: 0%;
    width: auto;
  }
  .resource-links {
    -ms-flex-preferred-size: 40%;
        flex-basis: 40%;
  }
  .copy,
  .social {
    width: 67%;
    -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
  }
}
@media only screen and (min-width: 901px) {
  .state-links {
    -ms-flex-preferred-size: 30%;
        flex-basis: 30%;
  }
  .state-links ul {
    -webkit-column-count: 1;
       -moz-column-count: 1;
            column-count: 1;
  }
  .popular-list-container {
    -ms-flex-preferred-size: 63.75%;
        flex-basis: 63.75%;
  }
  .popular-list-container li {
    width: 76%;
  }
  .resource-links {
    -ms-flex-preferred-size: 19%;
        flex-basis: 19%;
    margin: 0%;
  }
  .about-links {
    margin: 0% 0% 0% 13.5%;
  }
  .page-end-containers {
    -ms-flex-preferred-size: 33%;
        flex-basis: 33%;
  }
  .copy,
  .social {
    width: 92%;
  }
}
@media only screen and (min-width: 1200px) {
  .page-end-containers {
    -ms-flex-preferred-size: 32%;
        flex-basis: 32%;
  }
  .copy,
  .social {
    width: 87%;
  }
}
@media only screen and (min-width: 1400px) {
  .copy,
  .social {
    width: 81%;
  }
}
@media only screen and (min-width: 1601px) {
  .state-links {
    -ms-flex-preferred-size: 23%;
        flex-basis: 23%;
  }
  .popular-list-container {
    -ms-flex-preferred-size: 45%;
        flex-basis: 45%;
  }
  .popular-list-container ul {
    padding-right: 3%;
  }
  .about-links {
    margin: 0% 0% 0% 11%;
  }
  .page-end-containers {
    -ms-flex-preferred-size: 47%;
        flex-basis: 47%;
  }
  .copy,
  .social {
    width: 62%;
  }
}