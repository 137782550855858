:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.footer {
  background: #333333;
  color: #fff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}
.footer a {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
.footer a:hover {
  color: #fa6401;
}

.content {
  width: 100%;
  max-width: 1601px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.heading {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase;
  margin: 0 1rem 0 1rem;
}

.ul {
  padding: 0;
  margin: 2px 1rem 0 1rem;
  list-style: none;
}

.state-links {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  margin: 2rem 0;
  font-weight: bold;
}
.state-links ul {
  padding: 0;
  margin: 2px 0;
  -webkit-column-width: 16em;
     -moz-column-width: 16em;
          column-width: 16em;
  -webkit-column-count: 2;
     -moz-column-count: 2;
          column-count: 2;
  -webkit-column-gap: 0;
     -moz-column-gap: 0;
          column-gap: 0;
  list-style: none;
}
.state-links li {
  max-width: 15em;
  margin: 0 0.4rem 0 1rem;
}
@media only screen and (min-width: 601px) {
  .state-links {
    width: 100%;
  }
  .state-links ul {
    -webkit-column-width: auto;
       -moz-column-width: auto;
            column-width: auto;
    max-width: none;
  }
}
@media only screen and (min-width: 901px) {
  .state-links {
    width: 33em;
  }
}

.resource-links,
.about-links {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  margin: 2rem 0;
}
@media only screen and (min-width: 601px) {
  .resource-links,
  .about-links {
    width: 50%;
  }
}
@media only screen and (min-width: 901px) {
  .resource-links,
  .about-links {
    width: auto;
  }
}

.resource-links ul {
  margin-right: 1rem;
}

.page-end {
  width: 100%;
  max-width: 1601px;
  border-top: solid 2px #a9a9a9;
  padding: 1rem 0;
  text-align: center;
}
.page-end .social-follow {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 0 0.5rem 0 0.5rem;
}
.page-end .social,
.page-end .info {
  margin: auto;
  width: 100%;
}
.page-end .copy {
  font-size: 12px;
}
.page-end .facebook,
.page-end .twitter,
.page-end .instagram {
  cursor: pointer;
  margin: 0 0.5rem 0 0.5rem;
  position: relative;
  top: 5px;
}
.page-end .facebook:hover,
.page-end .twitter:hover,
.page-end .instagram:hover {
  -webkit-filter: brightness(0.5);
          filter: brightness(0.5);
}
.page-end .facebook {
  display: inline-block;
  background: url("/assets/images/lw/facebook.svg") no-repeat center center;
  width: 24px;
  height: 24px;
  background-size: contain;
}
.page-end .twitter {
  display: inline-block;
  background: url("/assets/images/lw/twitter.svg") no-repeat center center;
  width: 24px;
  height: 24px;
  background-size: contain;
}
.page-end .instagram {
  display: inline-block;
  background: url("/assets/images/lw/instagram.svg") no-repeat center center;
  width: 24px;
  height: 24px;
  background-size: contain;
}